import * as actionTypes from '../action/specialOffer.action';
export interface specialOfferList {
    specialOfferList:any,
    selectOfferData:any,
}

export const DEFAULT_SPECIALOFFER_LIST_STATE: specialOfferList = {
    specialOfferList: [],
    selectOfferData:{}
};

const reducer = (state=DEFAULT_SPECIALOFFER_LIST_STATE, action) => {
    switch (action.type) {
        case actionTypes.SPECIALOFFER_LIST:
            return {
                ...state,
                specialOfferList: action.payload
            };
        case actionTypes.SELECTED_SPECIALOFFERDATA:
                return {
                    ...state,
                    selectOfferData: action.payload
                };                                                 
            default:
                return state;
       
    };   

}
export default reducer;