import * as actionTypes from '../action/guestbook.action';
export interface firbaseList {
    guestBookList:any,
    selectedGuestbook:any
}

export const DEFAULT_GUESTBOOK_LIST_STATE: firbaseList = {
    guestBookList: [],
    selectedGuestbook:{}
};

const reducer = (state=DEFAULT_GUESTBOOK_LIST_STATE, action) => {
    switch (action.type) {
        case actionTypes.GUEST_BOOK_LIST:
            return {
                ...state,
                guestBookList: action.payload
            };
    case actionTypes.SELECTED_GUEST:
                return {
                    ...state,
                    selectedGuestbook: action.payload
                };  
            default:
                return state;
       
    };   

}
export default reducer;