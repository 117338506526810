import { combineReducers } from '@reduxjs/toolkit';
 import DEFAULT_FIRBASE_LIST_STATE from './reducers/firebase.reducers';
 import DEFAULT_USER_STATE from './reducers/user.reducers';
 import DEFAULT_GUESTBOOK_LIST_STATE from './reducers/guestbook.reducers';
 import DEFAULT_TRIPITINERARY_LIST_STATE from './reducers/tripitinerary.reducers';
 import DEFAULT_ROLODEX_LIST_STATE from './reducers/rolodex.reducers';
 import DEFAULT_SPECIALOFFER_LIST_STATE from './reducers/specialOffer.reducers';
const reducers = combineReducers({
    firbaseList:DEFAULT_FIRBASE_LIST_STATE,
    user:DEFAULT_USER_STATE,
    guestBookList:DEFAULT_GUESTBOOK_LIST_STATE,
    tripItineraryList:DEFAULT_TRIPITINERARY_LIST_STATE,
    rolodex:DEFAULT_ROLODEX_LIST_STATE,
    specialOffer:DEFAULT_SPECIALOFFER_LIST_STATE
});

export default reducers;
