import * as actionTypes from '../action/rolodex.action';
export interface rolodexlist {
    rolodexList:any,
    selectedRolodex:any,
    hotelRolodexList:any,
    selectedHotelRolodex:any,
    selectedInvoice:any,
    hotelRolodexGreavesList:any,
    selectedHotelRolodexGreaves:any,
    returnUrl:any,
    selectedMonth:any,
    selectedMonthAdmin:any,
    returnUrl:any,
    currencyList:any
}

export const DEFAULT_ROLODEX_LIST_STATE: rolodexlist = {
    rolodexList: [],
    selectedRolodex:{},
    hotelRolodexList: [],
    selectedHotelRolodex:{},
    selectedInvoice:{},
    hotelRolodexGreavesList:[],
    selectedHotelRolodexGreaves:{},
    returnUrl:'',
    selectedMonth:'',
    selectedMonthAdmin:'',
    currencyList:[],
};

const reducer = (state=DEFAULT_ROLODEX_LIST_STATE, action) => {
    switch (action.type) {
        case actionTypes.ROLODEX_LIST:
            return {
                ...state,
                rolodexList: action.payload
            };
        case actionTypes.SELECTED_ROLODEX:
                return {
                    ...state,
                    selectedRolodex: action.payload
                };
        case actionTypes.HOTEL_ROLODEX_LIST:
             return {
                        ...state,
                        hotelRolodexList: action.payload
                    };
        case actionTypes.SELECTED_HOTEL_ROLODEX:
                return {
                            ...state,
                            selectedHotelRolodex: action.payload
                 }; 
        case actionTypes.SELECTED_INVOICE:
             return {
                        ...state,
                        selectedInvoice: action.payload
                     };  
    case actionTypes.RETURN_URL:
                        return {
                                   ...state,
                                   returnUrl: action.payload
                                };   
    case actionTypes.SELECTED_MONTH:
                    return {
                         ...state,
                         selectedMonth: action.payload
                    }; 
    case actionTypes.SELECTED_MONTH_ADMIN:
        return {
                   ...state,
                   selectedMonthAdmin: action.payload
            };                                       
         case actionTypes.HOTEL_ROLODEX_GREAVES_LIST:
                        return {
                                    ...state,
                                    hotelRolodexGreavesList: action.payload
                         }; 
        case actionTypes.SELECTED_HOTEL_ROLODEX_GREAVES:
                     return {
                                ...state,
                                selectedHotelRolodexGreaves: action.payload
                             };
         case actionTypes.CURRENCY_LIST:
                     return {
                                ...state,
                                currencyList: action.payload
                            };                                                   
            default:
                return state;
       
    };   

}
export default reducer;