import * as actionTypes from '../action/firebase.action';
export interface firbaseList {
    schemaList:any,
    homeTopGalleryList:any,
    bookingTool:any,
    navigationList:any,
    topAirlineList:any,
    topCarVendorsList:any,
    topHotelChainsList:any,
    ttvSpendList:any,
    ourLocationList:any,
    advisorToolsList:any,
    covid19ResourcesList:any,
    blogPostsList:any,
    announcementList:any,
    resourceList:any,
    intranetPageList:any,
    selectedBlogPost:any,
    selectedAnnouncement:any,
    trainingList:any,
    icOperationalResourcesList:any,
    w9InvoiceTemplatesList:any,
    accommodationsList:any,
    landsList:any,
    tl365ProcessSupportContacts:any,
    tl365IndividualSupportContacts:any,
    supplierAccountCreationList:any,
    airNewsList:any,
    selectedAirNews:any,
}

export const DEFAULT_FIRBASE_LIST_STATE: firbaseList = {
    schemaList: [],
    homeTopGalleryList: [],
    bookingTool:{},
    navigationList:[],
    topAirlineList:[],
    topCarVendorsList:[],
    topHotelChainsList:[],
    ttvSpendList:[],
    ourLocationList:[],
    advisorToolsList:[],
    covid19ResourcesList:[],
    blogPostsList:[],
    announcementList:[],
    resourceList:[],
    intranetPageList:[],
    selectedBlogPost:{},
    selectedAnnouncement:{},
    trainingList:[],
    icOperationalResourcesList:[],
    w9InvoiceTemplatesList:[],
    accommodationsList:[],
    landsList:[],
    tl365ProcessSupportContacts:[],
    tl365IndividualSupportContacts:[],
    supplierAccountCreationList:[],
    airNewsList:[],
    selectedAirNews:{}
};

const reducer = (state=DEFAULT_FIRBASE_LIST_STATE, action) => {
    switch (action.type) {
        case actionTypes.BOOKING_TOOL:
            return {
                ...state,
                bookingTool: action.payload
            };
    case actionTypes.HOME_TOP_GALLERY_LIST:
                return {
                    ...state,
                    homeTopGalleryList: action.payload
                };    
        case actionTypes.SCHEMA_LIST:
            return {
                ...state,
                schemaList: action.payload.schemaList,
                navigationList: action.payload.navigationList
            }; 
       
        case actionTypes.NAVIGATION_LIST:
                    return {
                        ...state,
                        navigationList: action.payload
                    };
       case actionTypes.TOP_AIR_LINE_LIST:
                        return {
                            ...state,
                            topAirlineList: action.payload
                        };   
    case actionTypes.TOP_CAR_VENDORS_LIST:
                     return {
                                ...state,
                                topCarVendorsList: action.payload
                    };  
 case actionTypes.TOP_HOTEL_CHAINS_LIST:
                 return {
                        ...state,
                        topHotelChainsList: action.payload
                       };
case actionTypes.T_T_SPEND_LIST:
                return {
                          ...state,
                          ttvSpendList: action.payload
                    };
case actionTypes.OUR_LOCATION_LIST:
             return {
                     ...state,
                     ourLocationList: action.payload
                    };
case actionTypes.ADVISOR_TOOLS_LIST:
                return {
                         ...state,
                         advisorToolsList: action.payload
                }; 
case actionTypes.COVID_19_RESOURCE_LIST:
                    return {
                             ...state,
                             covid19ResourcesList: action.payload
                    };  
case actionTypes.BLOG_POSTS_LIST:
                return {
                        ...state,
                        blogPostsList: action.payload
                        };
case actionTypes.ANNOUNCEMENT_LIST:
                return {
                     ...state,
                     announcementList: action.payload
                    }; 
case actionTypes.RESOURCE_LIST:
                return {
                    ...state,
                    resourceList: action.payload
                }; 
case actionTypes.INTERNET_PAGE_LIST:
            return {
                        ...state,
                        intranetPageList: action.payload
            }; 
case actionTypes.INTERNET_PAGE_LIST:
            return {
                    ...state,
                    intranetPageList: action.payload
                }; 
case actionTypes.SELECTED_BLOG_POST:
        return {
                 ...state,
                 selectedBlogPost: action.payload
            }; 
case actionTypes.SELECTED_ANNOUNCEMENT:
        return {
                  ...state,
                  selectedAnnouncement: action.payload
            }; 
case actionTypes.TRAINING_LIST:
        return {
                  ...state,
                  trainingList: action.payload
            }; 
case actionTypes.IC_OPERATIONAL_RESOURCE_LIST:
        return {
                 ...state,
                 icOperationalResourcesList: action.payload
            }; 
case actionTypes.W9_INVOICE_TEMPLATE_LIST:
            return {
                    ...state,
                w9InvoiceTemplatesList: action.payload
            }; 
 case actionTypes.ACCOMMODATION_LIST:
         return {
                        ...state,
                        accommodationsList: action.payload
                };  
case actionTypes.LAND_LIST:
            return {
                                   ...state,
                                   landsList: action.payload
                };
case actionTypes.TL365_PROCESS_SUPPORT_CONTACTS:
         return {
                   ...state,
                   tl365ProcessSupportContacts: action.payload
               };  
case actionTypes.TL365_INDIVIDUAL_SUPPORT_CONTACTS:
            return {
                      ...state,
                      tl365IndividualSupportContacts: action.payload
                   };                                                                                                                                                                                            
case actionTypes.SUPPLIER_ACCOUNT_CREATION_LIST:
            return {
                              ...state,
                              supplierAccountCreationList: action.payload
                           };
case actionTypes.AIR_NEWS_LIST:
            return {
                      ...state,
                    airNewsList: action.payload
            };
case actionTypes.SELECTED_AIR_NEWS:
                return {
                          ...state,
                        selectedAirNews: action.payload
                };                                       
            default:
                return state;
       
    };   

}
export default reducer;