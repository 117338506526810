import React,{Suspense,lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
  // ,Navigate
} from "react-router-dom";
// Pages
const SiteComponentMain = lazy(() => import('../views/SiteComponentMain'));
const LoginComponentMain = lazy(() => import('../views/LoginComponentMain'));
const DownloadInvoiceComponent = lazy(() => import('../views/DownloadInvoiceComponent'));
const DownloadInvoiceReportingComponent = lazy(() => import('../views/DownloadInvoiceReportingComponent'));
const ItineraryDetailComponentMain=lazy(() => import('../views/itinerary/ItineraryDetailComponentMain'));
const ItineraryDetailDownloadComponentMain=lazy(() => import('../views/itinerary/ItineraryDetailDownloadComponentMain'));
const Navigator = () => {
  

  return (
    <Router>
       <Suspense fallback={<div id="vfx_loader_block">
  <div className="vfx-loader-item"> <img src="images/loading.gif" alt="" /> </div>
</div>}>
     
        <Routes>       
        <Route  path="/login"  element={<LoginComponentMain/>} />
        <Route  path="/itinerary-detail/*"  element={<ItineraryDetailComponentMain/>} />
        <Route  path="/itinerary-detail-download/*"  element={<ItineraryDetailDownloadComponentMain/>} />
        <Route  path="/download-invoice"  element={<DownloadInvoiceComponent/>} />
        <Route  path="/download-invoice-reporting"  element={<DownloadInvoiceReportingComponent/>} />      
        <Route  path="/*"  element={<SiteComponentMain/>} />
        </Routes> 
    
      </Suspense>
    </Router>
  );
};

export default Navigator;