import * as actionTypes from '../action/tripitinerary.action';
export interface firbaseList {
    tripItineraryList:any,
    selectedTripItinerary:any
}

export const DEFAULT_TRIPITINERARY_LIST_STATE: firbaseList = {
    tripItineraryList: [],
    selectedTripItinerary:{}
};

const reducer = (state=DEFAULT_TRIPITINERARY_LIST_STATE, action) => {
    switch (action.type) {
        case actionTypes.TRIP_ITINERARY_LIST:
            return {
                ...state,
                tripItineraryList: action.payload
            };
    case actionTypes.SELECTED_TRIP_ITINERARY:
                return {
                    ...state,
                    selectedTripItinerary: action.payload
                };  
            default:
                return state;
       
    };   

}
export default reducer;