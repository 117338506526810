
//import { useNavigate  } from "react-router-dom";
import './css/style.css';
import Navigator from "./components/Navigator";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDDaR9zksdi9O8xc2ua1Qr4ApIad2BpYVs",
  authDomain: "flamelink-demo-ee23b.firebaseapp.com",
  projectId: "flamelink-demo-ee23b",
  storageBucket: "flamelink-demo-ee23b.appspot.com",
  messagingSenderId: "529092879896",
  appId: "1:529092879896:web:5d375a7746dbcb663a856d",
  measurementId: "G-1DFHCBVL5B"
};
// Initialize Firebase

firebase.initializeApp(firebaseConfig);
const App = () => {
  //const history = useNavigate();
  return <Navigator/>;
};

export default App;
