export const SCHEMA_LIST = 'SCHEMA_LIST';
export const HOME_TOP_GALLERY_LIST = 'HOME_TOP_GALLERY_LIST';
export const NAVIGATION_LIST = 'NAVIGATION_LIST';
export const TOP_AIR_LINE_LIST = 'TOP_AIR_LINE_LIST';
export const TOP_CAR_VENDORS_LIST = 'TOP_CAR_VENDORS_LIST';
export const TOP_HOTEL_CHAINS_LIST = 'TOP_HOTEL_CHAINS_LIST';
export const T_T_SPEND_LIST = 'T_T_SPEND_LIST';
export const BOOKING_TOOL = 'BOOKING_TOOL';
export const OUR_LOCATION_LIST = 'OUR_LOCATION_LIST';
export const ADVISOR_TOOLS_LIST = 'ADVISOR_TOOLS_LIST';
export const COVID_19_RESOURCE_LIST = 'COVID_19_RESOURCE_LIST';
export const BLOG_POSTS_LIST = 'BLOG_POSTS_LIST';
export const ANNOUNCEMENT_LIST = 'ANNOUNCEMENT_LIST';
export const RESOURCE_LIST = 'RESOURCE_LIST';
export const INTERNET_PAGE_LIST = 'INTERNET_PAGE_LIST';
export const SELECTED_BLOG_POST = 'SELECTED_BLOG_POST';
export const SELECTED_ANNOUNCEMENT = 'SELECTED_ANNOUNCEMENT';
export const TRAINING_LIST = 'TRAINING_LIST';
export const IC_OPERATIONAL_RESOURCE_LIST = 'IC_OPERATIONAL_RESOURCE_LIST';
export const W9_INVOICE_TEMPLATE_LIST = 'W9_INVOICE_TEMPLATE_LIST';
export const ACCOMMODATION_LIST = 'ACCOMMODATION_LIST';
export const LAND_LIST = 'LAND_LIST';
export const TL365_PROCESS_SUPPORT_CONTACTS = 'TL365_PROCESS_SUPPORT_CONTACTS';
export const TL365_INDIVIDUAL_SUPPORT_CONTACTS = 'TL365_INDIVIDUAL_SUPPORT_CONTACTS';
export const SUPPLIER_ACCOUNT_CREATION_LIST = 'SUPPLIER_ACCOUNT_CREATION_LIST';
export const AIR_NEWS_LIST = 'AIR_NEWS_LIST';
export const SELECTED_AIR_NEWS = 'SELECTED_AIR_NEWS';