export const ROLODEX_LIST = 'ROLODEX_LIST';
export const SELECTED_ROLODEX= 'SELECTED_ROLODEX';
export const HOTEL_ROLODEX_LIST = 'HOTEL_ROLODEX_LIST';
export const SELECTED_HOTEL_ROLODEX= 'SELECTED_HOTEL_ROLODEX';
export const SELECTED_INVOICE= 'SELECTED_INVOICE';
export const HOTEL_ROLODEX_GREAVES_LIST= 'HOTEL_ROLODEX_GREAVES_LIST';
export const SELECTED_HOTEL_ROLODEX_GREAVES= 'SELECTED_HOTEL_ROLODEX_GREAVES';
export const RETURN_URL= 'RETURN_URL';
export const CURRENCY_LIST= 'CURRENCY_LIST';
export const SELECTED_MONTH= 'SELECTED_MONTH';
export const SELECTED_MONTH_ADMIN= 'SELECTED_MONTH_ADMIN';