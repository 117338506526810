import {LOGIN_TIME_NAME} from "../helpers/constants";
import {format} from "date-fns";
import {addDays as addDay} from "date-fns/addDays/index";
import {subDays as subDay} from "date-fns/subDays/index";
//const addDay = require('date-fns/add_days');
//const addMonth = require('date-fns/add_months');
//const subDay = require('date-fns/sub_days');
//import {parse} from "date-fns/parse";
//export  {format , parse};
export function isValidLogin(){
    let loginTime = localStorage.getItem(LOGIN_TIME_NAME);
   
    if(loginTime){
       // let loginValidDate = addHours(loginTime , 72);
        
       // return isFuture(loginValidDate);
        return true
    }else {
        return false;
    }
}
export function formatDate(date , form){
    return format(new Date(date) , form);
}
export function addDays(date  , days, format = 'MM/DD/YYYY'){
    return formatDate(addDay(new Date(date), days) , format)
}
export function subDays(date, days, format = 'MM/DD/YYYY'){
    return formatDate(subDay(new Date(date), days) , format)
}
export function getDayValue(date){
    let  myDate = new Date(date);
    let weekday=new Array(7)
 weekday[0]="Sun";
weekday[1]="Mon";
weekday[2]="Tue";
weekday[3]="Wed";
weekday[4]="Thu";
weekday[5]="Fri";
weekday[6]="Sat";
    return weekday[myDate.getDay()];
}

export function getMonthValue(date){
    let  myDate = new Date(date);
    let monthval=new Array(12)
    monthval[0]="January";
    monthval[1]="February";
    monthval[2]="March";
    monthval[3]="April";
    monthval[4]="May";
    monthval[5]="June";
    monthval[6]="July";
    monthval[7]="August";
    monthval[8]="September";
    monthval[9]="October";
    monthval[10]="November";
    monthval[11]="December";
    return monthval[myDate.getMonth()];
}
